import React from 'react';
import Layout from '../components/layout';
import styles from './joint-commission.module.scss';

export default ({ data }) => {
  return (
    <Layout>
      <div className={'columns'}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contentbox}`}>
            <div className={`${styles.tjclogo}`}>
              <h1 className={`title is-5 has-text-info ${styles.mainTitle}`}>
                Joint Commission Certified
              </h1>
              <hr />
              <p className={`content`}>
                Trustaff has been certified by{' '}
                <a
                  href="https://www.jointcommission.org/en/"
                  className={`content ${styles.links}`}
                >
                  The Joint Commission
                </a>{' '}
                and recognized with the <b>Gold Seal of Approval</b> for
                excellence in healthcare staffing.
              </p>
              <p className={`content`}>
                This certification demonstrates our ongoing commitment to great
                service, professional and ethical business practices, HIPAA
                compliance, and continued excellence. Learn more about The Joint
                Commission's healthcare staffing certification
              </p>
              <p className={`content`}>
                The public may contact The Joint Commission’s Office of Quality
                Monitoring to report any concerns or register complaints about a
                Joint Commission certified organization by calling
                1-800-994-6610 or emailing complaint@jointcommission.org.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
